import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WhoAreWe from "../../components/who-are-we"
import Sponsores from "../../components/sponsores"
import Realisation from "../../components/realisation"


class Sodexo extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Dejlivre" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-livre inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center">
                
                </h2>
            </div>
            <article className="article project inuit-u-p0">
                <div className="article__body article__body--has-extra">

                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            <a className="project__link" href="https://dejlivre.ma/" title="" target="_blank">
                                <img src="/img/clients/livre.jpg" alt="" className="project__logo" />
                            </a>
                        </p>

                        <p className="article__para inuit-u-p0">
                            <span className="project__detail">Livré !</span>
                            <span className="project__detail project__detail--emphasis">
                                <a className="project__link" href="https://dejlivre.ma/" title="PRECISION SYSTEMS" target="_blank">dejlivre.ma </a>
                            </span>
                            <span className="project__detail">Year 2021</span>
                        </p>
                    </aside>

                    <p className="v-center">
                        <h1 className="article__heading">Livré !.</h1>
                        <p className="article__para">
                        Manger sain et équilibré au bureau ce n'est pas toujours  évident et c'est important !
                        </p>
                    </p>

                </div>


                <p className="article__belt" style={{ marginTop: "0" }}>
                    <img className="" src={ require("../../images/projects/livre/iPhones-livre.jpg") } alt="mobile-apps" />
                </p>

                <div className="article__body">
                    <h2 className="article__heading"></h2>
                    <p className="article__para">
                    L'app Livré ! vous propose des mets frais et savoureux, préparés dans un environnement sanitaire strict en vous offrant une qualité de service inégalée à chaque étape de votre commande, le tout à un prix accessible.
                    </p>
                </div>

                <p className="article__belt inuit-u-mb0">
                    <img className="article__belt-image w-70" src={ require("../../images/projects/livre/AppStoreIconPropal.jpg") } alt="branding" />
                </p>

                {/* <p className="article__belt inuit-u-mb0">
                    <img className="article__belt-image" src="/img/projects/mysodexo/uxinfoaffilie.png" alt="branding" />
                </p> */}

                <br /><br />
            </article>
            
            <Realisation />
            <Sponsores />
            <WhoAreWe />
        </Layout>
    
  )

}

export default Sodexo
